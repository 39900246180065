import { createSlice, combineReducers, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { Howl } from 'howler';
import { RootState } from '../rootReducer';

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const playStates = {
  paused: 'paused',
  playing: 'playing',
  stopped: 'stopped',
  loading: 'loading',
};

export const volumeStates = {
  muted: 'muted',
  unmuted: 'unmuted',
};

let activeTrack: Howl | undefined = undefined;

const { actions: playerActions, reducer: playerReducer } = createSlice({
  name: 'player',
  initialState: {
    queue: [
        {
          title: "Overture - Who Are You",
          sources: [
            "https://cdn.sanity.io/files/gotgzth4/production/37d3890314087f70f281b35079153f1da9f53898.wav"
          ]
        },
        {
          "title": "Journey - Sophia ",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/471d84ba6b194927c1595e9a74ecc8026f1c81b5.wav"
          ]
        },
        {
          "title": "Into the unknown - Who Are You",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/de2e5303aaa9271516cae8697d59adc39ac5a749.wav"
          ]
        },
        {
          "title": "Nobody will forget you - Sophia",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/fd96b76c21917150f6e16db0a6a4321d12665511.mp3"
          ]
        },
        {
          "title": "Nearly day - Heritage",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/283d612497a0d2e4acc5d6cb827b607a45531f02.mp3"
          ]
        },
        {
          "title": "They're coming - Who Are You",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/3604a61fa785d15141e62f0f268b96cc105df629.wav"
          ]
        },
        {
          "title": "End Sequence - The Motion Picture Stills ",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/d97ce0164446dc0372f12c3a8a23ca4a4149c3cf.mp3"
          ]
        },
        {
          "title": "Wave like royalty - Sophia",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/496402ca480c1deb2f7d92e788941d5a5e171e87.wav"
          ]
        },
        {
          "title": "Strawberry fields - Heritage",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/886218104bd9a4a63578bbb543628789ac28c0a8.wav"
          ]
        },
        {
          "title": "The Motion Picture Stills film commission - Do Ho Suh film",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/cb39ad9b034fd6257b6c6672a7279b2d5956bfaa.mp3"
          ]
        },
        {
          "title": "The Sandman - The Motion Picture Stills",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/dbac5756adffc2f88285fa5a1585035258a215ab.mp3"
          ]
        },
        {
          "title": "Underwater - Bodies of Water",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/ed4e41489d4fe08157e71eaec23405b8d4fe945f.mp3"
          ]
        },
        {
          "title": "A journey - In The Light of Day",
          "sources": [
            "https://cdn.sanity.io/files/gotgzth4/production/6d8c0fd354aca491ebed55932f48b886887582d2.mp3"
          ]
        }
    ],
    queuePosition: 0,
    playState: playStates.stopped,
    volumeLevel: 0.8,
    volumeState: volumeStates.unmuted,
    nowPlaying: undefined,
  },
  reducers: {
    play: state => {
      state.playState = playStates.playing;
    },
    pause: state => {
      state.playState = playStates.paused;
    },
    load: state => {
      state.playState = playStates.loading;
    },
    stop: state => {
      if (state.playState !== playStates.loading) {
        state.playState = playStates.stopped;
      }
    },
    mute: state => {
      state.playState = volumeStates.muted;
    },
    unmute: state => {
      state.playState = volumeStates.unmuted;
    },
    setVolume: (state, action) => {
      state.volumeLevel = action.payload;
    },
    setQueuePosition: (state, action) => {
      state.queuePosition = action.payload;
    },
    setNowPlaying: (state, action) => {
      state.nowPlaying = action.payload;
    },
    clearQueue: state => {
      state.queue = [];
    },
    addToQueue: (state, action) => {
      state.queue.push(action.payload);
    },
    removeFromQueue: (state, action) => {
      state.queue.splice(action.payload, 1);
    },
    nextInQueue: state => {
      if (state.queuePosition + 1 < state.queue.length) {
        state.queuePosition = state.queuePosition + 1;
      } else {
        state.queuePosition = state.queue.length - 1;
      }
    },
    previousInQueue: state => {
      if (state.queuePosition - 1 > 0) {
        state.queuePosition = state.queuePosition - 1;
      } else {
        state.queuePosition = 0;
      }
    },
    setQueue: (state, action) => {
      state.queuePosition = action.payload.queuePosition;
      state.queue = action.payload.tracks;
    },
  },
});

const loadTrack = (
  track,
  shouldPlay = true,
  initialLoad = false
): AppThunk => async dispatch => {
  dispatch(playerActions.load());

  const trackPlayer = new Howl({
    autoplay: false,
    preload: shouldPlay,
    html5: true,
    src: track.sources,
    onplay: () => dispatch(playerActions.play()),
    onend: () => dispatch(next()),
    onpause: () => dispatch(playerActions.pause()),
    onstop: () => dispatch(playerActions.stop()),
    onmute: () => dispatch(playerActions.mute()),
    onvolume: (_id, volume) => {
      dispatch(playerActions.setVolume(volume));
    },
    onload: () => {
      if (!shouldPlay) {
        dispatch(playerActions.pause());
      }
    },
  });

  if (initialLoad) {
    dispatch(playerActions.pause());
  }

  if (activeTrack) {
    activeTrack.unload();
  }

  activeTrack = trackPlayer;

  if (shouldPlay) {
    activeTrack.play();
  }
};

export function play() {
  if (activeTrack) {
    activeTrack.load().play();
  }
  return { type: 'noop' };
}

export function pause() {
  if (activeTrack) {
    activeTrack.pause();
  }
  return { type: 'noop' };
}

export function next() {
  return (dispatch, getState) => {
    const { player } = getState();
    const { queue, queuePosition } = player;
    const track = queue[queuePosition + 1];

    if (!track) {
      dispatch(playerActions.stop());
    } else {
      dispatch(loadTrack(track));
      dispatch(playerActions.nextInQueue());
    }
  };
}

export function previous() {
  return (dispatch, getState) => {
    const { player } = getState();
    const { queue, queuePosition } = player;
    const track = queue[queuePosition - 1];

    if (!track) {
      dispatch(playerActions.stop());
    } else {
      dispatch(loadTrack(track));
      dispatch(playerActions.previousInQueue());
    }
  };
}

export function replaceQueue({ tracks = [], queuePosition = 0 }) {
  return dispatch => {
    const track = tracks[queuePosition];

    if (!track) {
      dispatch(playerActions.stop());
    } else {
      dispatch(loadTrack(track));
      dispatch(playerActions.setQueue({ tracks, queuePosition }));
    }
  };
}

export function initialisePlayer() {
  return (dispatch, getState) => {
    const { player } = getState();
    const { queue, queuePosition } = player;
    const track = queue[queuePosition];
    if (!activeTrack) {
      if (!track) {
        dispatch(stop());
      } else {
        dispatch(loadTrack(track, false, true));
      }
    }
  };
}

export const actions = {
  ...playerActions,
  play,
  pause,
  next,
  previous,
  replaceQueue,
  initialisePlayer,
};

export default playerReducer;
